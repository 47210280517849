import "./home.scss";

import Swiper from "swiper/bundle";

var swiper = new Swiper(".promoSwiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    observer: true,
    autoplay: {
        delay: 5000,
    },
    navigation: {
        nextEl: ".swiper-button-next-promo",
        prevEl: ".swiper-button-prev-promo",
    },
    breakpoints: {
        // when window width is >= 300px
        450: {
            slidesPerView: 2,
        },
        750: {
            slidesPerView: 3,
        },
        1050: {
            slidesPerView: 4,
        },
        // 1350: {
        //     slidesPerView: 5,
        // },
        // 1650: {
        //     slidesPerView: 6,
        // },
        // 1950: {
        //     slidesPerView: 7,
        // },
    },
});
